<template>
	<div class="animated fadeIn">
		<div v-if="$screen.width <= 567"></div>
		<div class="container-layout" v-else>
			<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
				{{ FormMSG(1, 'Scripts') }}
			</h1>
			<b-card class="card-border-blue-light">
				<!-- <pre>{{ scripts }}</pre> -->
				<b-row>
					<b-col cols="12">
						<card-list-builder v-if="$screen.width < 992" :items="scripts" :fields="fields">
							<template slot="actions" slot-scope="data">
								<b-button class="btn-transparent text-color-rhapsody-in-blue" @click="removeItem(data.item.id)">
									<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" @click="removeItem(data.item.id)" />
								</b-button>
							</template>
						</card-list-builder>
						<b-row v-if="$screen.width >= 992" class="form pb-3">
							<b-col sm="12" md="12" lg="6" xl="6">
								<b-input-group>
									<b-form-input type="text" v-model="filter" :placeholder="FormMSG(2, 'Type to search ...')" />
									<b-input-group-append class="cursor-pointer">
										<b-input-group-text class="btn-search">
											<component
												:is="getLucideIcon(ICONS.SEARCH.name)"
												:color="ICONS.SEARCH.color"
												:size="16"
												class="icon"
												:stroke-width="2.5"
												v-if="filter.length === 0"
											/>
											<component
												:is="getLucideIcon(ICONS.X.name)"
												:color="ICONS.SEARCH.color"
												:size="16"
												class="icon"
												:stroke-width="2.5"
												@click="filter = ''"
												v-else
											/>
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-col>
							<b-col sm="12" md="12" lg="6" xl="6">
								<b-button :block="!$screen.md" @click="newScript" variant="primary" size="md" class="btn btn-primary btn-md float-right">
									{{ FormMSG(3, 'New script') }}
								</b-button>
							</b-col>
						</b-row>
						<b-row v-if="$screen.width >= 992">
							<b-col cols="12" xl="12">
								<b-table
									responsive="sm"
									ref="scriptTable"
									:selected-variant="selectedColor"
									:hover="hover"
									:select-mode="selectMode"
									:items="scripts"
									:fields="fields"
									:head-variant="hv"
									:empty-text="FormMSG(4, 'No script found')"
									:current-page="currentPage"
									:filter="filter"
									:per-page="perPage"
									selectable
									bordered
									small
									show-empty
									sticky-header="800px"
								>
									<template #cell(edit)="data">
										<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" @click="editItem(data.item)" />
									</template>
									<template #cell(delete)="data">
										<component
											:is="getLucideIcon(ICONS.TRASH.name)"
											:color="ICONS.TRASH.color"
											:size="20"
											@click="removeItem(data.item.id)"
										/>
									</template>
								</b-table>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-card>
		</div>
		<form-modal
			v-model="isScriptFormOpen"
			:title="titleFormModal"
			:action="action"
			:item="item"
			@script-form-modal:closed="onCancelled"
			@script-form-modal:successed="onSuccessed"
		/>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import FormModal from '@/components/Script/FormModal';
import { getScripts, updateScript } from '@/cruds/script.crud';
// import { store } from '@/store'

export default {
	name: 'ScriptList',
	mixins: [languageMessages, globalMixin],
	components: {
		FormModal
	},
	computed: {
		typeOptions() {
			return this.FormMenu(1321);
		},
		baseFields() {
			return [
				{
					key: 'licenceId',
					label: this.FormMSG(5, 'Licence ID'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'license',
					label: this.FormMSG(6, 'Licence'),
					sortable: true
					// class: 'text-center'
				},
				{
					key: 'companyName',
					label: this.FormMSG(7, 'Company'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'projectId',
					label: this.FormMSG(8, 'Project ID'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'projectName',
					label: this.FormMSG(9, 'Project name'),
					sortable: true
					// class: 'text-center'
				},
				{
					key: 'scriptType',
					label: this.FormMSG(10, 'Script type'),
					sortable: true
					// class: 'text-center'
				},
				{
					key: 'languageName',
					label: this.FormMSG(11, 'Language'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'name',
					label: this.FormMSG(12, 'Script name'),
					sortable: true
					// class: 'text-center'
				},
				{
					key: 'fileName',
					label: this.FormMSG(13, 'File name'),
					sortable: true
					// class: 'text-center'
				},
				{
					key: 'outputFileFormat',
					label: this.FormMSG(26, 'Format'),
					sortable: false,
					class: 'text-center',
					formatter: (value) => {
						const menus = this.outputFormatOptions;

						return menus.filter((menu) => menu.value === value)[0].text.split(' ')[0];
					}
				},
				{
					key: 'edit',
					label: this.FormMSG(14, 'Edit'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				},
				{
					key: 'delete',
					label: this.FormMSG(15, 'Remove'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		fields() {
			return this.baseFields;
		},
		outputFormatOptions() {
			return this.FormMenu(11007);
		}
	},
	data() {
		return {
			filter: '',
			hover: true,
			currentPage: 1,
			perPage: 0,
			selectMode: 'single',
			selectedColor: 'primary',
			hv: 'dark',
			scripts: [],
			isScriptFormOpen: false,
			titleFormModal: '',
			action: '',
			item: {}
		};
	},
	async mounted() {
		await this.reload();
	},
	methods: {
		newScript() {
			this.titleFormModal = this.FormMSG(16, 'Add Script');
			this.action = 'ADD';
			this.item = {
				licenceId: null,
				projectId: null,
				type: null,
				language: null,
				fileName: '',
				companyName: '',
				name: '',
				templateImageChildXid: '',
				outputFileFormat: null
			};
			this.isScriptFormOpen = true;
		},
		onCancelled() {
			this.isScriptFormOpen = false;
		},
		async reload() {
			this.scripts = [];
			const scripts = await getScripts();
			for (const script of scripts) {
				script.languageName = this.languageString(script.language);
				this.scripts.push(script);
			}
		},
		async onSuccessed(payload) {
			let message = '';
			payload.languageName = this.languageString(payload.language);

			if (this.action === 'ADD') {
				this.scripts.unshift(payload);
				message = this.FormMSG(22, 'Script added successfully');
			} else if (this.action === 'UPDATE') {
				payload = {
					...payload,
					scriptType: this.typeOptions.filter((o) => o.value === payload.type)[0].text
				};
				this.scripts[this.scripts.findIndex((script) => script.id === payload.id)] = { ...payload };
				this.scripts = [...this.scripts];
				message = this.FormMSG(25, 'Script updated successfully');
			}

			if (this.$screen.width <= 567) {
				this.createToastForMobile(this.FormMSG(23, 'Success'), message, '');
			} else {
				this.$toast({
					message,
					title: this.FormMSG(23, 'Success'),
					variant: 'success'
				});
			}
			// await this.reload();
			this.isScriptFormOpen = false;
		},
		languageString(id) {
			let menus = this.FormMenu(1);

			menus.sort((a, b) => {
				if (a.text < b.text) {
					return -1;
				}
				if (a.text > b.text) {
					return 1;
				}

				return 0;
			});

			menus = [
				{
					value: -1,
					text: this.FormMSG(17, 'All')
				},
				...menus
			];

			return menus.filter((menu) => menu.value === id)[0].text;
		},
		removeItem(id) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(18, 'Are you sure to remove this script ?'), {
					title: this.FormMSG(19, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(20, 'YES'),
					cancelTitle: this.FormMSG(21, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;

					await updateScript(id, {
						toDelete: true
					}).then(async (record) => {
						this.scripts.splice(
							this.scripts.findIndex((script) => script.id === id),
							1
						);
						// await this.reload();
					});
				})
				.catch((err) => console.log(err));
		},
		editItem(item) {
			this.titleFormModal = this.FormMSG(24, 'Update Script');
			this.action = 'UPDATE';
			this.item = {
				...item
			};
			this.isScriptFormOpen = true;
		}
	}
};
</script>
